:root,
body {
    --color-black: #000000;
    --color-white: #FFFFFF;
    --color-red: #E03A3E;
    --color-teal: #00BAB4;
    --color-green: #295234;
    --color-green-inverse: #d6adcb;
    --color-light-green: #27CD79;
    --color-cream: #EEE1C6;
    --color-blue: #204391;
    --color-light-blue: #007AFF;
    --color-gray: #626262;
    --color-transparent-white: rgba(255, 255, 255, 0.3);
    --color-transparent-black: rgba(0, 0, 0, 0.3);

    --font-regular: normal 400 1em/1.2 'Barlow Condensed', sans-serif;
    --font-medium: normal 500 1em/1.2 'Barlow Condensed', sans-serif;
    --font-semi-bold: normal 700 1em/1.2 'Barlow Condensed', sans-serif;
    --font-dame: normal 400 1em/1.2 'League Gothic', sans-serif;
}
