* {
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font: var(--font-regular);
    font-size: 16px;
    letter-spacing: 0.05em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 1em;
}

a {
    text-decoration: none;
    color: inherit;
}
a:hover {
    text-decoration: underline;
}